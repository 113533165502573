"use strict";

import "../scss/black-dashboard.scss";

import { CountUp } from "countup.js";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { serializeEnvelope } from "@sentry/utils";
import { getEnvelopeEndpointWithUrlEncodedAuth } from "@sentry/core";

moment.locale("de");
moment.tz.setDefault("Europe/Berlin");

const renewablePsrTypes = [
    "b01",
    "b09",
    "b11",
    "b12",
    "b13",
    "b15",
    "b16",
    "b18",
    "b19",
];

const titles = {
    "block-generation": "Nettostromerzeugung pro Block NRW",
    "psr-generation": "Nettostromerzeugung pro Energieträger NRW",
    "total-generation": "Nettostromerzeugung und Verbrauch NRW",
    "total-emissions": "Emissionen der Nettostromerzeugung NRW",
    "installed-capacity": "Installierte Leistung aller Energieträger NRW",
    load: "Nettostromverbrauch NRW",
    flows: "Physikalische Lastflüsse NRW und europäisches Ausland",
    schedules: "Fahrpläne Lastflüsse NRW und europäisches Ausland",
    prices: "Strom- und Gaspreise (Spot)",
    "data-gaps": "Datenlücken",
    "plausibility-checks": "Plausibilitätschecks",
};

const psrTypes = {
    b01: "Biomasse",
    b02: "Braunkohle",
    b03: "Kohlengas",
    b04: "Erdgas",
    b05: "Steinkohle",
    b06: "Mineralöl",
    b07: "Fossil Oil shale",
    b08: "Fossil Peat",
    b09: "Geothermie",
    b10: "Pumpspeicher",
    b11: "Wasserkraft (Laufwasser)",
    b12: "Wasserspeicher",
    b13: "Marine",
    b14: "Kernenergie",
    b15: "Sonstige Erneuerbare Energien",
    b16: "Photovoltaik",
    b17: "Abfall",
    b18: "Windenergie (Offshore-Anlage)",
    b19: "Windenergie",
    b20: "Sonstige konventionelle Energien",
};

const generationUnits = {
    "11WD7LIES5B-KW-D": "GOWERK_KW",
    "11WD7HERN2S--KWU": "HERNE_KW",
    "11WD7BOLL5B-FREO": "WACHTBERG_KW",
    "11WD7KWHU----KW9": "GEITHE_KW",
    "11WD7IBBE2S--KW6": "IBBENBUEREN_KW",
    "11WD7BRAU5G-KW-N": "DORMAGEN_KW",
    "11WD7SCHO-S--KWW": "SCHOLVEN_KW",
    "11WD7STUM----KW9": "TR_LUENEN_KW",
    "11WD7BERG1S--KWB": "BERGKAMEN_KW",
    "11WD2VELT000176H": "VELTHEIM",
    "11WD7VOER1S--KWG": "VOERDE_KW",
    "11WD7BEEC5XRUKWN": "RUHRORT-KW",
    "11W0-0000-0043-Z": "KW_DATTELN_4",
    "11WD7KWKN----KW1": "KNAPSACK_KW",
    "11WD2HEKW000297X": "HEYDEN_KW",
    "11WD7KOEP2P--KWG": "KOEPCHENWERK_KW",
    "11WD7NEUR-B-KW-B": "NEURATH_KW",
    "11WD7GARE5XHEKWQ": "HERDECKE_KW",
    "11WD7RHAU5G-3--3": "SWDU_HKW_3",
    "11WD7WALS1S--KW0": "WALSUM_KW",
    "11WD7NORF5G--KWG": "LAUSWARD_KW",
    "11WD7HUCK2G--KWR": "HUCKINGEN_KW",
    "11WD7NIED-B-KW-5": "NIEDERAUSSEM_KW",
    "11WD7FUEH5GMEKW7": "MERKENICH_KW",
    "11WD7WEIS-B-KW-C": "WEISWEILER_KW",
    "11WD7WEST1S-KW-K": "WESTFALEN_KW",
    "11WD7FRIM2B-KW-M": "FRIMMERSDORF_KW",
    "11WD7BEEC5XHAKW5": "HAMBORN-KW",
    "11WD7KWKN1GBL203": "KNAPSACK_20",
    "11WD7BOLA--KWNIK": "NIEHL_KW",
    "11WD2GEBE000177K": "FRANKEN_I",
    "11WD7GERS-X-KW-C": "GERSTEINWERK_KW",
};
function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

let model;

async function updateExtremeValues(model) {
    if (
        ["installed-capacity", "data-gaps", "plausibility-checks"].includes(
            model.prefix
        )
    ) {
        return model;
    }
    let grids = model.data.data;
    let columns = grids[0].map(function (col, i) {
        return grids.map(function (row) {
            return row[i] ? row[i] : 0;
        });
    });
    let columnMap = {};
    for (let column of columns) {
        let name = column[0];
        columnMap[name] = column.slice(1);
    }
    var lowerBound = columnMap.iso_timestamp.findIndex(function (number) {
        return number > model.minDateSearch;
    });
    let maxDate = moment(model.maxDateSearch)
        .add(1, "days")
        .format("YYYY-MM-DD");
    var upperBound = [...columnMap.iso_timestamp].findIndex(function (number) {
        return number > maxDate;
    });
    if (upperBound === -1) {
        upperBound = columnMap.iso_timestamp.length - 1;
    } else {
        upperBound = upperBound - 1;
    }
    // Trim the columns before finding the maxes and mins
    for (let columnName in columnMap) {
        if (upperBound === columnMap[columnName].length) {
            columnMap[columnName] = columnMap[columnName].slice(lowerBound);
        } else {
            columnMap[columnName] = columnMap[columnName].slice(
                lowerBound,
                upperBound + 1
            );
        }
    }
    if (!model.extremeValues) {
        model.extremeValues = {};
    }
    for (let columnName in columnMap) {
        if (
            columnName !== "iso_timestamp" &&
            columnName !== "posix_timestamp"
        ) {
            let max_val = Math.max(...columnMap[columnName]);
            let max_index = columnMap[columnName].indexOf(max_val);
            let timestamp = columnMap["iso_timestamp"][max_index];
            let max_posix = columnMap["posix_timestamp"][max_index];
            let absolute_min =
                model.extremeValues[columnName] &&
                model.extremeValues[columnName]["absolute_min"]
                    ? model.extremeValues[columnName]["absolute_min"].value
                    : null;
            let min_val = Math.min(
                ...columnMap[columnName].map((x) =>
                    x < absolute_min ? Infinity : x
                )
            );
            let min_index = columnMap[columnName].indexOf(min_val);
            let min_timestamp = columnMap["iso_timestamp"][min_index];
            let min_posix = columnMap["posix_timestamp"][min_index];
            if (!model.extremeValues[columnName]) {
                model.extremeValues[columnName] = { timerange_max: {} };
            }
            if (!max_val && min_val === Infinity) {
                model.extremeValues[columnName]["timerange_max"] = null;
                model.extremeValues[columnName]["timerange_min"] = null;
            } else {
                model.extremeValues[columnName]["timerange_max"] = {
                    iso_timestamp: timestamp,
                    value: max_val,
                    posix_timestamp: max_posix,
                };
                model.extremeValues[columnName]["timerange_min"] = {
                    iso_timestamp: min_timestamp,
                    value: min_val,
                    posix_timestamp: min_posix,
                };
            }
        }
    }
    return model;
}

async function makeExtremeValuesRows(model) {
    let rows = [];
    for (let seriesName in model.extremeValues) {
        if (model.visibleColumns.has(seriesName)) {
            for (let measurementName in model.extremeValues[seriesName]) {
                if (model.extremeValues[seriesName][measurementName]) {
                    rows.push([
                        seriesName,
                        measurementName,
                        model.extremeValues[seriesName][measurementName]
                            .iso_timestamp,
                        model.extremeValues[seriesName][measurementName].value,
                        model.extremeValues[seriesName][measurementName]
                            .posix_timestamp,
                    ]);
                }
            }
        }
    }
    return rows;
}

async function updateExtremeValuesTable(model) {
    if (model.prefix === "installed-capacity") {
        return model;
    }
    let rows = await makeExtremeValuesRows(model);
    model.extremeValuesTable.clear().rows.add(rows).draw();
    return model;
}

let debounceDashboardMinMax = null;
function updateAfterDashboardMinMax(e) {
    clearTimeout(debounceDashboardMinMax);
    debounceDashboardMinMax = setTimeout(function () {
        let newMax = Highcharts.dateFormat("%Y-%m-%d", new Date(e.max));
        let oldMax = $("#max-date-input").val();
        model.maxDateSearch = newMax;

        if (newMax != oldMax) {
            $("#max-date-input").val(newMax);
            model.maxDateSearch = newMax;
        }
        let newMin = Highcharts.dateFormat("%Y-%m-%d", new Date(e.min));
        let oldMin = $("#min-date-input").val();
        model.minDateSearch = newMin;

        if (newMin != oldMin) {
            $("#min-date-input").val(newMin);
            model.minDateSearch = newMin;
        }
        model.tableObjects.table.draw();
        updateExtremeValues(model);
        updateExtremeValuesTable(model);
    }, 300);
}

function initSentry() {
    // Set up Sentry
    let sentryDsn = JSON.parse(
        document.getElementById("sentry-dsn").textContent
    );
    let sentryRelease = JSON.parse(
        document.getElementById("sentry-release").textContent
    );
    let sentryEnv = JSON.parse(
        document.getElementById("sentry-env").textContent
    );
    try {
        Sentry.init({
            dsn: sentryDsn,
            environment: sentryEnv,
            integrations: [new BrowserTracing()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            release: sentryRelease,
            tracesSampleRate: 0,
        });
    } catch {
        console.log("Unable to set up Sentry.");
    }
}

function makeExcelDate(date) {
    let timestamp = moment.tz(date, "Europe/Berlin");
    // Add the offset so that the resulting unix time is correct
    timestamp.add(timestamp._offset, "minutes");
    return 25569 + timestamp.unix() / 86400;
}

function initSidebarCollapse() {
    $(document).ready(function () {
        // Toggle arrow on show hide of collapse element
        $(".collapse").on("show.bs.collapse", function () {
            $(this).prev(".row").find(".menu-header").addClass("active");
        });
        $(".collapse").on("hide.bs.collapse", function () {
            $(this).prev(".row").find(".menu-header").removeClass("active");
        });
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    });
}

function animateTickerNumbers() {
    document.addEventListener("DOMContentLoaded", function () {
        $("script[id$='-ticker-config']").each(function (i, el) {
            let config = JSON.parse(document.getElementById(el.id).textContent);
            let options = {
                separator: ".",
                decimal: ",",
            };
            config.tickers.forEach((ticker) => {
                if (ticker.value === null) {
                    document.getElementById(
                        `${ticker.prefix}-ticker-value`
                    ).textContent = "—";
                } else {
                    new CountUp(
                        `${ticker.prefix}-ticker-value`,
                        ticker.value,
                        options
                    ).start();
                }
            });
        });
    });
}

let parameterNames = [
    "psr_type",
    "year",
    "resolution",
    "electricity_unit",
    "unit_of_measure",
];

async function makeDataView(prefix, oldModel = null) {
    let plotContainer = $(`#${prefix}-plot`);
    let tableContainer = $(`#${prefix}-table-container`);
    let extremeValuesTableContainer = $(
        `#${prefix}-extreme-values-table_wrapper`
    );
    plotContainer.addClass("hidden");
    tableContainer.addClass("hidden");
    extremeValuesTableContainer.addClass("hidden");
    let dataviewSpinnerContainer = $(
        `#${prefix}-dataview-loading-spinner-container`
    );
    let extremeValuesSpinnerContainer = $(
        `#${prefix}-extreme-values-loading-spinner-container`
    );
    dataviewSpinnerContainer.removeClass("hidden");
    extremeValuesSpinnerContainer.removeClass("hidden");
    let viewType = $(`#${prefix}-form-view`).val();
    if (!viewType) {
        viewType = "table";
    }

    let labels = {
        iso_timestamp: "Start",
        load_val: "Nettostromverbrauch NRW",
        residual_load_value: "Residuallast NRW",
        consumption_b10_val: "Einspeicherung der Pumpspeicherwerke",
        flow_be_nw_val: "Belgien-NRW",
        flow_nl_nw_val: "Niederlande-NRW",
        flow_nw_be_val: "NRW-Belgien",
        flow_nw_nl_val: "NRW-Niederlande",
        flow_net_nrw_val: "Nettostromexport NRW-Ausland",
        flow_net_de_val: "Nettostromexport DE-Ausland",
        flow_net_nrw_be_val: "Stromexport NRW-BE",
        flow_net_nrw_nl_val: "Stromexport NRW-NL",
        schedule_be_nw_a01: "Belgien-NRW Day Ahead",
        schedule_nl_nw_a01: "Niederlande-NRW Day Ahead",
        schedule_nw_be_a01: "NRW-Belgien Day Ahead",
        schedule_nw_nl_a01: "NRW-Niederlande Day Ahead",
        schedule_be_nw_a05: "Belgien-NRW Total",
        schedule_nl_nw_a05: "Niederlande-NRW Total",
        schedule_nw_be_a05: "NRW-Belgien Total",
        schedule_nw_nl_a05: "NRW-Niederlande Total",
        day_ahead_price: "Strompreis (Day-Ahead EPEX Spot)",
        gas_price: "Gaspreis (Trading Hub Europe Spot)",
        b01_val: "Biomasse",
        b02_val: "Braunkohle",
        b03_val: "Fossil Coal-derived gas",
        b04_val: "Erdgas",
        b05_val: "Steinkohle",
        b06_val: "Mineralöl",
        b07_val: "Fossil Oil shale",
        b08_val: "Fossil Peat",
        b09_val: "Geothermie",
        b10_val: "Pumpspeicher",
        b11_val: "Wasserkraft (Laufwasser)",
        b12_val: "Wasserspeicher",
        b13_val: "Marine",
        b14_val: "Kernenergie",
        b15_val: "Sonstige Erneuerbare Energien",
        b16_val: "Photovoltaik",
        b17_val: "Abfall",
        b18_val: "Windenergie (Offshore-Anlage)",
        b19_val: "Windenergie",
        b20_val: "Sonstige konventionelle Energien",
        conventionals_value: "Summe konventionelle Energien",
        renewables_value: "Summe Erneuerbare Energien",
        total_value: "Summe",
        renewables_percent: "Anteil Erneuerbare Energien",
        em_b01_value: "Biomasse",
        em_b02_value: "Braunkohle",
        em_b03_value: "Fossil Coal-derived gas",
        em_b04_value: "Erdgas",
        em_b05_value: "Steinkohle",
        em_b06_value: "Mineralöl",
        em_b07_value: "Fossil Oil shale",
        em_b08_value: "Fossil Peat",
        em_b09_value: "Geothermie",
        em_b10_value: "Pumpspeicher",
        em_b11_value: "Wasserkraft (Laufwasser)",
        em_b12_value: "Wasserspeicher",
        em_b13_value: "Marine",
        em_b14_value: "Kernenergie",
        em_b15_value: "Sonstige Erneuerbare Energien",
        em_b16_value: "Photovoltaik",
        em_b17_value: "Abfall",
        em_b18_value: "Windenergie (Offshore-Anlage)",
        em_b19_value: "Windenergie",
        em_b20_value: "Sonstige konventionelle Energien",
        ic_b01_value: "Biomasse",
        ic_b02_value: "Braunkohle",
        ic_b03_value: "Fossil Coal-derived gas",
        ic_b04_value: "Erdgas",
        ic_b05_value: "Steinkohle",
        ic_b06_value: "Mineralöl",
        ic_b07_value: "Fossil Oil shale",
        ic_b08_value: "Fossil Peat",
        ic_b09_value: "Geothermie",
        ic_b10_value: "Pumpspeicher",
        ic_b11_value: "Wasserkraft (Laufwasser)",
        ic_b12_value: "Wasserspeicher",
        ic_b13_value: "Marine",
        ic_b14_value: "Kernenergie",
        ic_b15_value: "Sonstige Erneuerbare Energien",
        ic_b16_value: "Photovoltaik",
        ic_b17_value: "Abfall",
        ic_b18_value: "Windenergie (Offshore-Anlage)",
        ic_b19_value: "Windenergie",
        ic_b20_value: "Sonstige konventionelle Energien",
        ic_b01_percent: "Biomasse",
        ic_b02_percent: "Braunkohle",
        ic_b03_percent: "Fossil Coal-derived gas",
        ic_b04_percent: "Erdgas",
        ic_b05_percent: "Steinkohle",
        ic_b06_percent: "Mineralöl",
        ic_b07_percent: "Fossil Oil shale",
        ic_b08_percent: "Fossil Peat",
        ic_b09_percent: "Geothermie",
        ic_b10_percent: "Pumpspeicher",
        ic_b11_percent: "Wasserkraft (Laufwasser)",
        ic_b12_percent: "Wasserspeicher",
        ic_b13_percent: "Marine",
        ic_b14_percent: "Kernenergie",
        ic_b15_percent: "Sonstige Erneuerbare Energien",
        ic_b16_percent: "Photovoltaik",
        ic_b17_percent: "Abfall",
        ic_b18_percent: "Windenergie (Offshore-Anlage)",
        ic_b19_percent: "Windenergie",
        ic_b20_percent: "Sonstige konventionelle Energien",
        timerange_min: "Zeitbereich Min.",
        timerange_max: "Zeitbereich Max.",
        absolute_min: "Absolute Min.",
        absolute_max: "Absolute Max.",
        posix_timestamp: "POSIX",
        control_area: "Control-Area",
        psr_type: "Energieträger",
        status: "Status",
        error: "Fehler",
        b_11wd7kwkn_kw_een_val: "Knapsack 1",
        b_11wd7kwkn_20_eew_val: "Knapsack 2",
        b_11wd7norf5gagudc_val: "Block AGuD",
        b_11wd7norf5g_hter_val: "Block E",
        b_11wd7boll5bfrfbc_val: "Fabrik Frechen/Wachtberg",
        b_11wd7frim2bdoubledashp_g_val: "Frimmersdorf P",
        b_11wd7frim2bdoubledashq_d_val: "Frimmersdorf Q",
        b_11wd7gers_g_blf3_val: "Gersteinwerk F",
        b_11wd7gers_g_blg1_val: "Gersteinwerk G",
        b_11wd7gers_g_bliy_val: "Gersteinwerk I",
        b_11wd7gers5s_k1_j_val: "Gersteinwerk K1",
        b_11wd7gers1s_k2_7_val: "Gersteinwerk K2",
        b_11wd7lies5b_wrkc_val: "Goldenberg GoWerk",
        b_11wd7brau5g_gudh_val: "GuD Dormagen",
        b_11wd7fueh5gmerka_val: "GuD-Anlage-HKW-Merkenich",
        b_11wd7rhau5gkw3b5_val: "HKW IIIB",
        b_11wd7huck2gdoubledasha_c_val: "Huckingen A",
        b_11wd7huck2gdoubledashb_9_val: "Huckingen B",
        b_11wd7ibbe2sdoubledashb_p_val: "Ibbenbüren B",
        b_11wd7kwhu1gbl10e_val: "KW Hamm-Uentrop Block 10",
        b_11wd7kwhu1gbl20b_val: "KW Hamm-Uentrop Block 20",
        b_11wd7stum1sbl10g_val: "KW Lünen Block 1",
        b_11wd7koep2pdoubledash5_g_val: "Koepchenwerk",
        b_11wd7voer1sdoubledasha_1_val: "Kraftwerk Voerde Block A",
        b_11wd7voer1sdoubledashb_z_val: "Kraftwerk Voerde Block B",
        b_11wd7neur2bdoubledasha_t_val: "Neurath A",
        b_11wd7neur1bdoubledashb_x_val: "Neurath B",
        b_11wd7neur1bdoubledashc_u_val: "Neurath C",
        b_11wd7neur1bdoubledashd_r_val: "Neurath D",
        b_11wd7neur1bdoubledashe_o_val: "Neurath E",
        b_11wd7neur1bdoubledashf_l_val: "Neurath F",
        b_11wd7neur1bdoubledashg_i_val: "Neurath G",
        b_11wd7nied2bdoubledashc_h_val: "Niederaußem C",
        b_11wd7nied2bdoubledashd_e_val: "Niederaußem D",
        b_11wd7nied2bdoubledashe_b_val: "Niederaußem E",
        b_11wd7nied2bdoubledashf_8_val: "Niederaußem F",
        b_11wd7nied1bdoubledashg_c_val: "Niederaußem G",
        b_11wd7nied1bdoubledashh_9_val: "Niederaußem H",
        b_11wd7nied1bdoubledashk_0_val: "Niederaußem K (BoA 1)",
        b_11wd7scho1sdoubledashb_0_val: "Scholven B",
        b_11wd7scho2sdoubledashc_r_val: "Scholven C",
        b_11wd7weis5bdoubledashe_y_val: "Weisweiler E",
        b_11wd7weis1bdoubledashf_m_val: "Weisweiler F",
        b_11wd7weis1bdoubledashg_j_val: "Weisweiler G",
        b_11wd7weis1bdoubledashh_g_val: "Weisweiler H",
        b_11wd7weis5gvgtgu_val: "Weisweiler VGT - BI. G",
        b_11wd7weis5gvgths_val: "Weisweiler VGT - BI. H",
        b_11wd7west5sdoubledashc_p_val: "Westfalen C",
        b_11wd7wsfn1sdoubledashe_z_val: "Westfalen E",
        b_11wd7herd2g_h6_x_val: "HERDECKE_H6",
        b_11wd7hern2s_3doubledashw_val: "HERNE_3",
        b_11wd7hern2sdoubledash4_x_val: "HERNE_4",
        b_11wd7wals1sdoubledash10a_val: "WALSUM_10",
        b_11wd7berg1sdoubledasha_x_val: "BERGKAMEN_A",
        b_11wd7beec5xham4i_val: "HAMBORN-4",
        b_11wd7beec5xham5g_val: "HAMBORN-5",
        b_11wd7beec5xruh4e_val: "RUHRORT-4",
        b_11wd7norf5ggudfa_val: "Block F",
        b_11wd7opla1gdoubledash318_val: "NIEHL-3",
        b_11wd7bola5gdtn2t_val: "NIEHL-II-DT",
        b_11wd7bola5ggtn2e_val: "NIEHL-II-GT",
        b_11w0_0000_0044_w_val: "Datteln 4",
        b_11wd2hey40000638_val: "Heyden",
        b_11wd2vel3000053j_val: "Veltheim 3",
        em_total_value: "Summe Emissionen NRW",
    };

    labels = {
        ...labels,
        ...generationUnits,
    };

    function getSeriesLabel(name, prefix, isPercentChart) {
        if (prefix === "psr-generation") {
            if (name.startsWith("ic_")) {
                return "Installierte Leistung";
            }
        }
        if (isPercentChart) {
            if (name === "conventionals_value") {
                return "Anteil konventionelle Energien";
            }
            if (name === "renewables_value") {
                return "Anteil Erneuerbare Energien";
            }
        }
        return labels[name];
    }

    let axis_labels = {
        mw: "Megawatt [MW]",
        mwh: "Megawattstunden [MWh]",
        price: "Euro pro Megawattstude [EUR/MWh]",
        percent: "Prozent [%]",
    };

    let unitsOfMeasure = {
        mw: "MW",
        mwh: "MWh",
        price: "EUR/MWh",
        percent: "%",
        tco2: "tCO2",
    };

    let resolutions = {
        quarter_hour: "Viertelstunde",
        hourly: "Stunde",
        daily: "Tag",
        weekly: "Woche",
        monthly: "Monat",
        quarterly: "Quartal",
        half_yearly: "Halbjahr",
        yearly: "Jahr",
    };

    let reversePsrTypes = {};
    for (let k in psrTypes) {
        reversePsrTypes[psrTypes[k]] = k;
        reversePsrTypes[psrTypes[k].toUpperCase()] = k;
    }

    let reverseRenewables = [];
    let reverseConventionals = [];

    for (let k in psrTypes) {
        if (renewablePsrTypes.includes(k)) {
            reverseRenewables.push(psrTypes[k]);
            reverseRenewables.push(psrTypes[k].toUpperCase());
        } else {
            reverseConventionals.push(psrTypes[k]);
            reverseConventionals.push(psrTypes[k].toUpperCase());
        }
    }

    let model = {
        prefix: null,
        data: null,
        chart: null,
        tableObjects: null,
        endpoint: null,
        params: {},
        minDate: null,
        maxDate: null,
        minDateSearch: null,
        maxDateSearch: null,
        extremeValues: {},
        visibleColumns: new Set(),
        columnsForExport: [],
    };

    async function getEndpoint(model) {
        let endpoint = `/api/v1/${model.prefix}`;
        if (!["data-gaps", "plausibility-checks"].includes(model.prefix)) {
            endpoint = `${endpoint}/timeseries`;
        }
        return endpoint;
    }

    async function getData(model) {
        let params = JSON.parse(JSON.stringify(model.params));
        delete params.year;
        params = Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v != "")
        );
        let response = await fetch(
            `${model.endpoint}?${new URLSearchParams(params)}`
        );
        let data = await response.json();
        return data;
    }

    async function getExtremeValues(model) {
        if (
            ["installed-capacity", "data-gaps", "plausibility-checks"].includes(
                model.prefix
            )
        ) {
            return model;
        }
        let params = JSON.parse(JSON.stringify(model.params));
        if (model.prefix === "psr-generation") {
            params.dataset_name = params.psr_type;
        } else {
            params.dataset_name = model.prefix;
        }
        delete params.year;
        delete params.start;
        delete params.end;
        delete params.unit_of_measure;
        delete params.psr_type;
        let endpoint = `/api/v1/extreme-values`;
        let response = await fetch(
            `${endpoint}?${new URLSearchParams(params)}`
        );
        let dataset = await response.json();
        return dataset.data;
    }

    function getYAxisLabel(meta) {
        let label;
        if (prefix == "total-emissions") {
            label = "Emissionen [tCO2]";
        } else if (meta.electricity_unit) {
            label = axis_labels[meta.electricity_unit];
        } else if (meta.unit_of_measure) {
            label = axis_labels[meta.unit_of_measure];
        } else {
            label = axis_labels["price"];
        }
        return label;
    }

    async function makeTable(model, oldModel = null) {
        let prefix = model.prefix;
        let tableData = model.data;
        let tableDiv = $(`#${prefix}-table`);
        let columns = [];
        let columnNames = [];
        let formatPercents =
            model.params.unit_of_measure === "percent" ? true : false;
        tableData.data.slice(0, 1)[0].forEach((element, index) => {
            columnNames.push(element);
            let column = {
                title: getSeriesLabel(element, prefix, formatPercents),
                name: element,
            };
            if (index > 0) {
                let significantDigits = 0;
                if (prefix === "prices" || formatPercents) {
                    if (element !== "posix_timestamp") {
                        significantDigits = 2;
                    }
                }
                let _renderFunction = DataTable.render.number(
                    ".",
                    ",",
                    significantDigits,
                    ""
                );
                let renderFunction;
                if (
                    element === "renewables_percent" ||
                    (formatPercents && element !== "posix_timestamp")
                ) {
                    renderFunction = function (data, type, row, meta) {
                        if (data == null) {
                            return data;
                        }
                        data = parseFloat(data) * 100;
                        return _renderFunction.display(data);
                    };
                } else {
                    renderFunction = _renderFunction;
                }
                column.render = renderFunction;
            } else if (index === 0) {
                column.render = function (data, type, row, meta) {
                    return moment(data).format("YYYY-MM-DD HH:mm:ss");
                };
            }
            if (element === "posix_timestamp") {
                column.visible = false;
            }
            if (prefix === "flows") {
                if (
                    element.startsWith("flow_") &&
                    element !== "flow_net_nrw_val"
                ) {
                    column.visible = false;
                }
            }
            if (formatPercents) {
                if (
                    element === "renewables_percent" ||
                    element === "total_value"
                ) {
                    column.visible = false;
                }
            }
            columns.push(column);
        });
        let data = tableData.data.slice(1);
        if (oldModel) {
            let xMin;
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                ) ||
                ["data-gaps", "plausibility-checks"].includes(model.prefix)
            ) {
                try {
                    xMin = moment.tz(data[0][0], "Europe/Berlin");
                } catch {
                    xMin = moment("2015-01-01");
                }
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    let _xMin = moment.tz(
                        data[data.length - 1][0],
                        "Europe/Berlin"
                    );
                    _xMin.date(1);
                    if (_xMin.month() !== 0) {
                        _xMin.month(_xMin.month() - 1);
                    }
                    _xMin.hour(0);
                    _xMin.minute(0);
                    xMin = _xMin;
                } else {
                    xMin = moment.tz([year, 10, 1, 0, 0], "Europe/Berlin");
                }
            }
            let firstDate;
            let datepickerMin;
            try {
                firstDate = moment(data[0][0]).add(1, "days").toDate();
                datepickerMin = data[0][0].slice(0, 10);
            } catch {
                firstDate = moment("2015-01-01").toDate();
                datepickerMin = "2015-01-01";
            }
            if (oldModel.minDateSearch.slice(0, 4) === xMin.format("YYYY")) {
                model.minDate = oldModel.minDate;
                model.minDateSearch = oldModel.minDateSearch;
                model.datepickerMin = datepickerMin;
            } else {
                model.minDate = oldModel.minDate;
                model.minDateSearch = oldModel.minDateSearch;
                model.datepickerMin = datepickerMin;
                model.minDate.val(xMin.format("YYYY-MM-DD"));
                model.minDate.min(firstDate);
                model.minDateSearch = xMin.format("YYYY-MM-DD");
            }

            let xMax;
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                ) ||
                ["data-gaps", "plausibility-checks"].includes(model.prefix)
            ) {
                try {
                    xMax = moment.tz(data[data.length - 1][0], "Europe/Berlin");
                } catch {
                    xMax = moment(moment.now());
                }
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    xMax = moment.tz(data[data.length - 1][0], "Europe/Berlin");
                } else {
                    xMax = moment.tz([year, 11, 31, 23, 45], "Europe/Berlin");
                }
            }
            let _lastDate;
            let lastDate;
            let datepickerMax;
            try {
                lastDate = moment
                    .tz(data[data.length - 1][0], "Europe/Berlin")
                    .toDate();
                datepickerMax = data[data.length - 1][0].slice(0, 10);
            } catch {
                _lastDate = moment(moment.now());
                lastDate = _lastDate.toDate();
                datepickerMax = _lastDate.format("YYYY-MM-DD");
            }
            if (oldModel.maxDateSearch.slice(0, 4) === xMax.format("YYYY")) {
                model.maxDate = oldModel.maxDate;
                model.maxDateSearch = oldModel.maxDateSearch;
                model.datepickerMax = datepickerMax;
            } else {
                model.maxDate = oldModel.maxDate;
                model.maxDateSearch = oldModel.maxDateSearch;
                model.datepickerMax = datepickerMax;
                model.maxDate.val(xMax.format("YYYY-MM-DD"));
                model.maxDate.max(lastDate);
                model.maxDateSearch = xMax.format("YYYY-MM-DD");
            }
        } else {
            let xMin;
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                ) ||
                ["data-gaps", "plausibility-checks"].includes(model.prefix)
            ) {
                try {
                    xMin = moment.tz(data[0][0], "Europe/Berlin");
                } catch {
                    xMin = moment("2015-01-01");
                }
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    let _xMin = moment.tz(
                        data[data.length - 1][0],
                        "Europe/Berlin"
                    );
                    _xMin.date(1);
                    if (_xMin.month() !== 0) {
                        _xMin.month(_xMin.month() - 1);
                    }
                    _xMin.hour(0);
                    _xMin.minute(0);
                    xMin = _xMin;
                } else {
                    xMin = moment.tz([year, 10, 1, 0, 0], "Europe/Berlin");
                }
            }
            let firstDate;
            let datepickerMin;
            try {
                firstDate = moment(data[0][0]).add(1, "days").toDate();
                datepickerMin = data[0][0].slice(0, 10);
            } catch {
                firstDate = moment("2015-01-01").toDate();
                datepickerMin = "2015-01-01";
            }
            model.datepickerMin = datepickerMin;
            let minDate = new DateTime($("#min-date-input"), {
                format: "YYYY-MM-DD",
                // minDate: new Date(data[0][0]),
                minDate: firstDate,
                i18n: {
                    previous: "Zurück",
                    next: "Nächste",
                    months: [
                        "Januar",
                        "Februar",
                        "März",
                        "April",
                        "Mai",
                        "Juni",
                        "Juli",
                        "August",
                        "September",
                        "Oktober",
                        "November",
                        "Dezember",
                    ],
                    weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                },
                firstDay: 1,
            });
            // minDate.val(data[0][0].split("T")[0]);
            minDate.val(xMin.format("YYYY-MM-DD"));
            model.minDate = minDate;
            model.minDateSearch = xMin.format("YYYY-MM-DD");
            let xMax;
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                ) ||
                ["data-gaps", "plausibility-checks"].includes(model.prefix)
            ) {
                try {
                    xMax = moment.tz(data[data.length - 1][0], "Europe/Berlin");
                } catch {
                    xMax = moment(moment.now());
                }
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    xMax = moment.tz(data[data.length - 1][0], "Europe/Berlin");
                } else {
                    xMax = moment.tz([year, 11, 31, 23, 45], "Europe/Berlin");
                }
            }
            let _lastDate;
            let lastDate;
            let datepickerMax;
            try {
                lastDate = moment
                    .tz(data[data.length - 1][0], "Europe/Berlin")
                    .toDate();
                datepickerMax = data[data.length - 1][0].slice(0, 10);
            } catch {
                _lastDate = moment(moment.now());
                lastDate = _lastDate.toDate();
                datepickerMax = _lastDate.format("YYYY-MM-DD");
            }
            model.datepickerMax = datepickerMax;
            let maxDate = new DateTime($("#max-date-input"), {
                format: "YYYY-MM-DD",
                minDate: firstDate,
                maxDate: lastDate,
                i18n: {
                    previous: "Zurück",
                    next: "Nächste",
                    months: [
                        "Januar",
                        "Februar",
                        "März",
                        "April",
                        "Mai",
                        "Juni",
                        "Juli",
                        "August",
                        "September",
                        "Oktober",
                        "November",
                        "Dezember",
                    ],
                    weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                },
                firstDay: 1,
            });
            maxDate.val(xMax.format("YYYY-MM-DD"));
            model.maxDate = maxDate;
            model.maxDateSearch = xMax.format("YYYY-MM-DD");
        }
        if ($.fn.dataTable.isDataTable(tableDiv)) {
            oldModel.tableObjects.table.destroy();
            tableDiv.empty();
        }
        let table = tableDiv.DataTable({
            data: data,
            columns: columns,
            deferRender: true,
            destroy: true,
            pagingType: "full_numbers",
            language: {
                searchPlaceholder: "Daten durchsuchen",
                search: "_INPUT_",
                paginate: {
                    first: "Erste",
                    previous: "Zurück",
                    next: "Nächste",
                    last: "Letzte",
                },
                thousands: ".",
                zeroRecords: "Keine passenden Einträge gefunden",
                emptyTable: "Keine Daten in der Tabelle vorhanden",
                info: "_START_ bis _END_ von _TOTAL_ Einträgen",
                infoEmpty: "Keine Daten vorhanden",
                infoFiltered: "(gefiltert von _MAX_ Einträgen)",
                lengthMenu: "_MENU_ Einträge anzeigen",
                loadingRecords: "Wird geladen ..",
                processing: "Bitte warten ..",
            },
            responsive: false,
            scrollX: true,
            buttons: [
                {
                    extend: "csv",
                    filename: function () {
                        return makeFilename();
                    },
                    exportOptions: {
                        columns: function (idx, data, node) {
                            let name = columns[idx].name;
                            if (model.visibleColumns.has(name)) {
                                return true;
                            }
                            if (name === "posix_timestamp") {
                                return true;
                            }
                            return false;
                        },
                        format: {
                            body: function (data, row, column, node) {
                                if (column === 0) {
                                    if (data == "") {
                                        return data;
                                    } else {
                                        return moment
                                            .tz(data, "Europe/Berlin")
                                            .format("YYYY-MM-DDTHH:mm:ssZ");
                                    }
                                } else {
                                    return data.replaceAll(".", "");
                                }
                            },
                        },
                    },
                },
                {
                    extend: "excel",
                    filename: function () {
                        return makeFilename();
                    },
                    title: function () {
                        return makeExcelTitle();
                    },
                    exportOptions: {
                        columns: function (idx, data, node) {
                            let name = columns[idx].name;
                            if (model.visibleColumns.has(name)) {
                                return true;
                            }
                            if (name === "posix_timestamp") {
                                return true;
                            }
                            return false;
                        },
                        format: {
                            body: function (data, row, column, node) {
                                if (column === 0) {
                                    if (data == "") {
                                        return data;
                                    } else {
                                        return makeExcelDate(data);
                                    }
                                } else {
                                    return data.replaceAll(".", "");
                                }
                            },
                        },
                    },
                    customize: function (xlsx, data) {
                        let sheet = xlsx.xl.worksheets["sheet1.xml"];
                        $('row c[r^="' + "A" + '"]', sheet).attr("s", "68");
                    },
                },
            ],
            lengthChange: true,
            dom: "rtlip",
        });

        // Add the download buttons
        table
            .buttons()
            .container()
            .addClass("hidden")
            .appendTo(tableDiv.parent().parent());
        columnNames.forEach((element) => {
            if (prefix !== "flows") {
                model.visibleColumns.add(element);
            }
            if (prefix === "flows") {
                if (element.startsWith("flow_")) {
                    if (element === "flow_net_nrw_val") {
                        model.visibleColumns.add(element);
                    }
                } else {
                    model.visibleColumns.add(element);
                }
            }
        });
        $(`#${prefix}-table`)
            .css("width", "100%")
            .dataTable()
            .fnAdjustColumnSizing();
        return {
            table: table,
            columnNames: columnNames,
        };
    }

    async function makeExtremeValuesTable(model, oldModel = null) {
        if (
            ["installed-capacity", "data-gaps", "plausibility-checks"].includes(
                model.prefix
            )
        ) {
            return null;
        }
        let spinnerContainer = $(
            `#${model.prefix}-extreme-values-loading-spinner-container`
        );
        let prefix = model.prefix;
        let tableData = await makeExtremeValuesRows(model);
        let tableDiv = $(`#${prefix}-extreme-values-table`);
        if (oldModel) {
            oldModel.extremeValuesTable.destroy();
        }
        let titles = ["Quelle", "Min-/Max-Art", "Start", "Wert", "POSIX"];
        let names = ["source", "type", "start", "value", "posix_timestamp"];
        let columns = [];
        let includeSourceColumn = true;
        tableData.slice(0, 1)[0].forEach((element, index) => {
            let column = {
                title: titles[index],
                name: names[index],
            };
            if (index === 3) {
                let significantDigits;
                let _renderFunction = DataTable.render.number(
                    ".",
                    ",",
                    significantDigits,
                    ""
                );
                let renderFunction = function (data, type, row, meta) {
                    let rowName = row[0];
                    switch (rowName) {
                        case "day_ahead_price":
                            significantDigits = 2;
                            break;
                        case "gas_price":
                            significantDigits = 2;
                            break;
                        case "renewables_percent":
                            significantDigits = 2;
                            data = parseFloat(data) * 100;
                            break;
                        default:
                            significantDigits = 0;
                    }
                    return _renderFunction.display(data);
                };
                column.render = renderFunction;
            } else if (index === 2) {
                column.render = function (data, type, row, meta) {
                    return moment(data).format("YYYY-MM-DD HH:mm:ss");
                };
            } else if (index === 0) {
                column.render = function (data, type, row, meta) {
                    if (prefix === "psr-generation" && data.startsWith("ic_")) {
                        return "Installierte Leistung";
                    }
                    return labels[data];
                };
                if (
                    ![
                        "total-generation",
                        "total-emissions",
                        "load",
                        "flows",
                        "schedules",
                        "psr-generation",
                        "block-generation",
                        "prices",
                    ].includes(model.prefix)
                ) {
                    column.visible = false;
                    includeSourceColumn = false;
                }
            } else if (index === 1) {
                column.render = function (data, type, row, meta) {
                    return labels[data];
                };
            }
            if (column.name === "posix_timestamp") {
                column.visible = false;
            }
            columns.push(column);
        });
        let data = tableData;
        let dateColumn = includeSourceColumn ? 3 : 2;
        let dateColumnLetter = dateColumn === 3 ? "C" : "B";

        spinnerContainer.addClass("hidden");

        let table = tableDiv.DataTable({
            data: data,
            columns: columns,
            deferRender: true,
            destroy: true,
            pagingType: "full_numbers",
            language: {
                searchPlaceholder: "Daten durchsuchen",
                search: "_INPUT_",
                paginate: {
                    first: "Erste",
                    previous: "Zurück",
                    next: "Nächste",
                    last: "Letzte",
                },
                thousands: ".",
                zeroRecords: "Keine passenden Einträge gefunden",
                emptyTable: "Keine Daten in der Tabelle vorhanden",
                info: "_START_ bis _END_ von _TOTAL_ Einträgen",
                infoEmpty: "Keine Daten vorhanden",
                infoFiltered: "(gefiltert von _MAX_ Einträgen)",
                lengthMenu: "_MENU_ Einträge anzeigen",
                loadingRecords: "Wird geladen ..",
                processing: "Bitte warten ..",
            },
            responsive: false,
            buttons: [
                {
                    extend: "csv",
                    filename: function () {
                        return `Extremwerte-${makeFilename()}`;
                    },
                    exportOptions: {
                        columns: [":visible", "posix_timestamp:name"],
                        format: {
                            body: function (data, row, column, node) {
                                if (column === dateColumn - 1) {
                                    if (data == "") {
                                        return data;
                                    } else {
                                        return moment
                                            .tz(data, "Europe/Berlin")
                                            .format("YYYY-MM-DDTHH:mm:ssZ");
                                    }
                                } else {
                                    return data.toString().replaceAll(".", "");
                                }
                            },
                        },
                    },
                },
                {
                    extend: "excel",
                    filename: function () {
                        return `Extremwerte-${makeFilename()}`;
                    },
                    title: function () {
                        return `Extremwerte: ${makeExcelTitle()}`;
                    },
                    exportOptions: {
                        columns: [":visible", "posix_timestamp:name"],
                        format: {
                            body: function (data, row, column, node) {
                                if (column === dateColumn - 1) {
                                    if (data == "") {
                                        return data;
                                    } else {
                                        return makeExcelDate(data);
                                    }
                                } else {
                                    return data.toString().replaceAll(".", "");
                                }
                            },
                        },
                    },
                    customize: function (xlsx, data) {
                        let sheet = xlsx.xl.worksheets["sheet1.xml"];
                        $(`row c[r^="${dateColumnLetter}"]`, sheet).attr(
                            "s",
                            "68"
                        );
                        $(`row c[r^="A"]`, sheet).attr("s", "50");
                    },
                },
            ],
            lengthChange: true,
            dom: "rtlip",
        });
        // Add the download buttons
        table
            .buttons()
            .container()
            .addClass("hidden")
            .appendTo(tableDiv.parent().parent());
        return table;
    }

    function makeFilename() {
        let filename = titles[model.prefix].replaceAll(" ", "-");
        for (let parameterName of parameterNames) {
            if (parameterName !== "year") {
                let formElement = $(`#${model.prefix}-form-${parameterName}`);
                if (!formElement || formElement.val() === undefined) {
                    continue;
                }
                let parameterTitle;
                let parameterValue = formElement.val();
                switch (parameterName) {
                    case "resolution":
                        parameterTitle = resolutions[parameterValue];
                        break;
                    case "electricity_unit":
                        parameterTitle = unitsOfMeasure[parameterValue];
                        break;
                    case "unit_of_measure":
                        parameterTitle = unitsOfMeasure[parameterValue];
                        break;
                    case "psr_type":
                        parameterTitle = psrTypes[parameterValue]
                            ? psrTypes[parameterValue]
                            : "Alle Erzeugungsarten";
                        break;
                    default:
                        console.log("Title not found");
                }
                filename = `${filename}_${parameterTitle.replaceAll(" ", "-")}`;
            }
        }
        let start = moment(model.minDate.val()).format("YYYY-MM-DD");
        let end = moment(model.maxDate.val()).format("YYYY-MM-DD");
        filename = `${filename}_${start}-${end}`;
        return `${filename}`;
    }

    function makeExcelTitle() {
        let title = titles[model.prefix];
        for (let parameterName of parameterNames) {
            if (parameterName !== "year") {
                let formElement = $(`#${model.prefix}-form-${parameterName}`);
                if (!formElement || formElement.val() === undefined) {
                    continue;
                }
                let parameterTitle;
                let parameterValue = formElement.val();
                switch (parameterName) {
                    case "resolution":
                        parameterTitle = resolutions[parameterValue];
                        break;
                    case "electricity_unit":
                        parameterTitle = unitsOfMeasure[parameterValue];
                        break;
                    case "psr_type":
                        parameterTitle = psrTypes[parameterValue];
                        break;
                    default:
                        console.log("Title not found");
                }
                title = `${title} - ${parameterTitle}`;
            }
        }
        let start = moment(model.minDate.val()).format("YYYY-MM-DD");
        let end = moment(model.maxDate.val()).format("YYYY-MM-DD");
        title = `${title} - vom ${start} bis ${end}`;
        return title;
    }

    function getUnitOfMeasure(meta, prefix, seriesName) {
        if (prefix === "psr-generation") {
            if (seriesName.startsWith("ic_")) {
                return unitsOfMeasure["mw"];
            }
        }
        if (seriesName === "day_ahead_price") {
            return unitsOfMeasure.price;
        }
        if (seriesName === "gas_price") {
            return unitsOfMeasure.price;
        }
        if (seriesName === "renewables_percent") {
            return unitsOfMeasure.percent;
        }
        if (meta.unit_of_measure) {
            return unitsOfMeasure[meta.unit_of_measure];
        }
        return unitsOfMeasure[meta.electricity_unit];
    }

    function updateAllTables(model) {
        model.tableObjects.table.draw();
        updateExtremeValuesTable(model);
    }

    async function makeChart(model, oldModel = null) {
        if (["data-gaps", "plausibility-checks"].includes(model.prefix)) {
            return null;
        }
        let prefix = model.prefix;
        let chartData = model.data;
        let isPercentChart =
            model.params.unit_of_measure === "percent" ? true : false;

        Highcharts.setOptions({
            lang: {
                decimalPoint: ",",
                thousandsSep: ".",
                numericSymbols: ["Tsd.", "Mio", "Mrd."],
                loading: "Daten werden geladen...",
                months: [
                    "Januar",
                    "Februar",
                    "März",
                    "April",
                    "Mai",
                    "Juni",
                    "Juli",
                    "August",
                    "September",
                    "Oktober",
                    "November",
                    "Dezember",
                ],
                weekdays: [
                    "Sonntag",
                    "Montag",
                    "Dienstag",
                    "Mittwoch",
                    "Donnerstag",
                    "Freitag",
                    "Samstag",
                ],
                shortMonths: [
                    "Jan",
                    "Feb",
                    "Mär",
                    "Apr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Okt",
                    "Nov",
                    "Dez",
                ],
                exportButtonTitle: "Exportieren",
                printButtonTitle: "Drucken",
                rangeSelectorFrom: "Von",
                rangeSelectorTo: "Bis",
                rangeSelectorZoom: "Zeitraum",
                downloadPNG: "Download als PNG-Bild",
                downloadJPEG: "Download als JPEG-Bild",
                downloadPDF: "Download als PDF-Dokument",
                downloadSVG: "Download als SVG-Bild",
                resetZoom: "Zoom zurücksetzen",
                resetZoomTitle: "Zoom zurücksetzen",
            },
        });
        let chartType;
        let yAxisMin;
        let yAxisMax;
        let meta = chartData.metadata;
        let data = chartData.data;
        if (prefix === "installed-capacity") {
            for (let row of data) {
                row[0] = row[0].replace("-12-31T23:45", "-01-01T00:00");
                row.pop();
            }
        }

        chartType = "line";
        yAxisMin = -1;
        if (["load", "schedules", "psr-generation"].includes(prefix)) {
            yAxisMin = 0;
            chartType = "line";
            // yAxisMax = 10000;
        }
        if (["prices"].includes(prefix)) {
            yAxisMin = null;
            chartType = "line";
        }
        if (["flows"].includes(prefix)) {
            yAxisMin = null;
            chartType = "line";
        }
        if (
            [
                "total-generation",
                "block-generation",
                "total-emissions",
            ].includes(prefix)
        ) {
            yAxisMin = 0;
            chartType = "area";
        }
        if (["installed-capacity"].includes(prefix)) {
            yAxisMin = 0;
            chartType = "column";
        }
        if (isPercentChart) {
            yAxisMax = 1;
        }

        let series = [];

        let colorMap = {
            b01: "#51b351", // Biomass
            b02: "#9a7b5c", // Fossil Brown coal/
            b03: "#452ef2", // Coal gas
            b04: "#f29200", // Fossil Gas
            b05: "#414141", // Fossil Hard coal
            b06: "#788075", // Fossil Oil
            b09: "#a3cd5c", // Geothermal
            b10: "#435474", // Hydro Pumped Storage
            b11: "#A6E2FF", // Hydro Run-of-river and poundage
            b12: "#a6ccff", // Hydro Water Reservoir
            b15: "#87cd5c", // Other renewable
            b16: "#FFE200", // Solar
            b17: "#bee3be", // Waste
            b19: "#5A67FF", // Wind Onshore
            b20: "#758075", // Other
            load: "#9467bd", // Load
            renewables: "#fc03c2", // Renewables
            default: "#51b351", // Default
            consumption_b10: "#A6E2FF", // Hydro Pumped Storage consumption
        };
        let colors = [];
        let alternativeColors = [];
        for (let x in colorMap) {
            alternativeColors.push(colorMap[x]);
        }
        if (!["flows", "schedules", "prices"].includes(prefix)) {
            for (let x of data[0].slice(1)) {
                if (
                    [
                        "conventionals_value",
                        "renewables_value",
                        "total_value",
                    ].includes(x)
                ) {
                    continue;
                }
                x = x
                    .replace("ic_", "")
                    .replace("_value", "")
                    .replace("_val", "")
                    .replace("_percent", "");
                let color = colorMap[x];
                if (color) {
                    colors.push(color);
                } else {
                    colors.push(colorMap.default);
                }
            }
        }

        colors = colors.concat(alternativeColors);
        if (prefix === "block-generation") {
            colors = [
                "#7cb5ec",
                "#434348",
                "#90ed7d",
                "#f7a35c",
                "#8085e9",
                "#f15c80",
                "#e4d354",
                "#2b908f",
                "#f45b5b",
                "#91e8e1",
            ];
        }
        let yAxisLabel = getYAxisLabel(meta);
        function getNumberFormatting(element) {
            return prefix === "prices" || isPercentChart
                ? { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                : { maximumFractionDigits: 0 };
        }

        let secondaryAxis;
        if (prefix === "psr-generation") {
            secondaryAxis = {
                // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: "Installierte Leistung [MW]",
                },
                // labels: {
                //     formatter: function () {
                //         return `${this.value * 100}`;
                //     },
                // },
                min: 0,
                max: meta.max_value,
                // linkedTo: 0,
            };
        } else if (
            prefix === "block-generation" ||
            prefix == "total-emissions"
        ) {
            secondaryAxis = {};
        } else {
            secondaryAxis = {
                // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: "Prozent [%]",
                },
                labels: {
                    formatter: function () {
                        return `${this.value * 100}`;
                    },
                },
                min: 0,
                max: 1,
            };
        }
        let yAxis = [
            {
                min: yAxisMin,
                max: prefix === "psr-generation" ? meta.max_value : yAxisMax,
                title: {
                    text: yAxisLabel,
                },
                opposite: false,
                // linkedTo: 1,
            },
            secondaryAxis,
        ];
        if (isPercentChart) {
            yAxis[0].labels = {
                formatter: function () {
                    return `${this.value * 100}`;
                },
            };
        }
        if (
            !["area", "column"].includes(chartType) &&
            !(prefix === "psr-generation")
        ) {
            yAxis.splice(1);
        }
        let navigatorSeries;
        if (chartType === "area") {
            navigatorSeries = {
                stacking: "normal",
                type: "area",
                fillOpacity: 1,
            };
        } else {
            navigatorSeries = {};
        }
        let xMin;
        let xMax;
        if (
            oldModel &&
            oldModel.params.year === $(`#${model.prefix}-form-year`).val()
        ) {
            xMin = oldModel.chart.xAxis[0].userMin;
            xMax = oldModel.chart.xAxis[0].userMax;
        } else {
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                )
            ) {
                xMin = moment.tz(data[1][0], "Europe/Berlin").unix() * 1000;
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    let _xMin = moment.tz(
                        data[data.length - 1][0],
                        "Europe/Berlin"
                    );
                    _xMin.date(1);
                    if (_xMin.month() !== 0) {
                        _xMin.month(_xMin.month() - 1);
                    }
                    _xMin.hour(0);
                    _xMin.minute(0);
                    xMin = _xMin.unix() * 1000;
                } else {
                    xMin =
                        moment.tz([year, 10, 1, 0, 0], "Europe/Berlin").unix() *
                        1000;
                }
            }
            if (
                ["quarterly", "half_yearly", "yearly"].includes(
                    model.data.metadata.resolution
                )
            ) {
                xMax =
                    moment
                        .tz(data[data.length - 1][0], "Europe/Berlin")
                        .unix() * 1000;
            } else {
                let year = parseInt(model.params.year);
                if (year == moment().year()) {
                    xMax =
                        moment
                            .tz(data[data.length - 1][0], "Europe/Berlin")
                            .unix() * 1000;
                } else {
                    xMax =
                        moment
                            .tz([year, 11, 31, 23, 45], "Europe/Berlin")
                            .unix() * 1000;
                }
            }
        }
        let height;
        if (
            [
                "total-generation",
                "total-emissions",
                "installed-capacity",
                "flows",
                "schedules",
            ].includes(prefix)
        ) {
            height = 550;
        } else if (prefix === "block-generation") {
            height = 1000;
        } else {
            height = 500;
        }
        function format_xaxis() {
            // We need to adjust the dates for the installed capacity
            // plot so that they are displayed as LANUV expects.
            // This is a terrible hack to get around an issue with Highcharts.
            if (prefix === "installed-capacity") {
                let d = new Date(this.value);
                d.setMonth(12);
                d.setDate(0);
                return d.toLocaleDateString("de-DE");
            }
            return new Date(this.value).toLocaleDateString("de-DE");
        }
        let chart = Highcharts.stockChart(
            `${prefix}-plot`,
            {
                chart: {
                    // alignTicks: false,
                    animation: false,
                    type: chartType,
                    height: height,
                    spacingBottom: [
                        "total-generation",
                        "total-emissions",
                        "installed-capacity",
                        "flows",
                        "schedules",
                        "block-generation",
                    ].includes(prefix)
                        ? 50
                        : 0,
                    events: {
                        load: function () {
                            if (
                                ![
                                    "total-generation",
                                    "total-emissions",
                                    "installed-capacity",
                                    "flows",
                                    "schedules",
                                    "block-generation",
                                ].includes(prefix)
                            ) {
                                return;
                            }
                            let normalState = {
                                fill: "black",
                                style: {
                                    color: "white",
                                },
                            };
                            let hoverState = {
                                stroke: "black",
                                fill: "white",
                                style: {
                                    color: "black",
                                },
                            };
                            function callback() {
                                $.each(chart.series, function (i, seriex) {
                                    let name = seriex.name;
                                    let isVisible = seriex.visible;
                                    if (!name.startsWith("Nav") && !isVisible) {
                                        seriex.setVisible(true, false);
                                    }
                                });
                                chart.redraw();
                                setTimeout(() => {
                                    let cols = [];
                                    let visibility = [];
                                    let table = model.tableObjects.table;
                                    $.each(chart.series, function (i, seriex) {
                                        let name = seriex.name;
                                        if (!name.startsWith("Nav")) {
                                            cols.push(`${name}:name`);
                                            visibility.push(true);
                                            model.visibleColumns.add(name);
                                        } else {
                                            return false;
                                        }
                                    });
                                    table.columns(cols).visible(visibility);
                                    debounce(updateAllTables(model));
                                }, 500);
                            }
                            this.renderer
                                .button(
                                    "Alle zeigen",
                                    this.chartWidth / 2 - 20,
                                    this.chartHeight - 40,
                                    callback,
                                    normalState,
                                    hoverState
                                )
                                .attr({
                                    id: "btn1",
                                })
                                .add();
                        },
                    },
                },
                exporting: {
                    sourceWidth: 884,
                    sourceHeight: 500,
                    enabled: false,
                    fallbackToExportServer: false,
                },
                colors: colors,
                xAxis: {
                    type: "datetime",
                    startOfWeek: 2,
                    labels: {
                        format:
                            prefix !== "installed-capacity"
                                ? "{value:%b %e}"
                                : null,
                        formatter: format_xaxis, // Override the format--this is a terrible hack to get around an issue with Highcharts.
                    },
                    events: {
                        afterSetExtremes(e) {
                            let event = new Event("dashboardMinMax", {
                                bubbles: true,
                            });
                            event.min = e.min;
                            event.max = e.max;
                            document.body.dispatchEvent(event);
                        },
                    },
                    min: xMin,
                    max: chartType === "column" ? null : xMax,
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<table><tr><th colspan="2">{point.key}</th></tr>',
                    pointFormatter: function () {
                        let tooltipColor;
                        if (this.color === "transparent") {
                            tooltipColor = "#000000";
                        } else {
                            if (
                                prefix === "psr-generation" &&
                                this.series.name.startsWith("ic_")
                            ) {
                                tooltipColor = "#000000";
                            } else {
                                tooltipColor = this.color;
                            }
                        }
                        let value;
                        if (this.series.name === "renewables_percent") {
                            value = this.y * 100;
                        } else if (isPercentChart) {
                            value = this.y * 100;
                        } else {
                            value = this.y;
                        }
                        return (
                            `<tr><td style="color: ${tooltipColor}">${getSeriesLabel(
                                this.series.name,
                                prefix,
                                isPercentChart
                            )}</td>` +
                            `<td style="padding-left: 10px; text-align: right"><b>${value.toLocaleString(
                                "de-DE",
                                getNumberFormatting(this.series.name)
                            )} ${getUnitOfMeasure(
                                meta,
                                prefix,
                                this.series.name
                            )}</b></td></tr>`
                        );
                    },
                    footerFormat: "</table>",
                    valueDecimals: 2,
                    shared: true,
                    split: false,
                },
                yAxis: yAxis,
                rangeSelector: null,
                plotOptions: {
                    area: {
                        stacking: "normal",
                        dataGrouping: false,
                        animation: false,
                    },
                    column: {
                        stacking: "normal",
                        dataGrouping: false,
                        animation: false,
                    },
                    line: {
                        dataGrouping: false,
                        animation: false,
                    },
                    series: {
                        animation: false,
                        dataGrouping: {
                            enabled: false,
                        },
                        events: {
                            legendItemClick: function () {
                                let name = this.name;
                                let isVisible = this.visible;
                                setTimeout(() => {
                                    if (isVisible) {
                                        model.visibleColumns.delete(name);
                                    } else {
                                        model.visibleColumns.add(name);
                                    }
                                    model.tableObjects.columnNames.forEach(
                                        (element) => {
                                            if (element === name) {
                                                model.tableObjects.table
                                                    .column(`${name}:name`)
                                                    .visible(!isVisible);
                                            }
                                        }
                                    );
                                    debounce(updateAllTables(model));
                                }, 500);
                            },
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                navigator: {
                    enabled: chartType === "column" ? false : true,
                    series: navigatorSeries,
                },
                series: series,
                data: {
                    rows: data,
                    complete: function (parsedData) {
                        if (prefix !== "installed-capacity") {
                            parsedData.series = parsedData.series.slice(0, -1);
                        }
                        if (["area", "column"].includes(chartType)) {
                            if (prefix === "block-generation") {
                                // let newParsedSeries = []
                                let renewables = [];
                                let conventionals = [];
                                let navs = [];
                                let currentPsrType = null;
                                let recs = [];
                                let dict = {};
                                let generationUnit = null;
                                let colorCount = -1;
                                let maxColors = alternativeColors.length - 1;
                                for (let series of parsedData.series) {
                                    if (meta.series[series.name]) {
                                        let psrType =
                                            meta.series[
                                                series.name
                                            ].psr_type.toLowerCase();
                                        let newGenerationUnit =
                                            meta.series[series.name]
                                                .generation_unit;
                                        if (
                                            newGenerationUnit !== generationUnit
                                        ) {
                                            colorCount = colorCount + 1;
                                            if (colorCount > maxColors) {
                                                colorCount = 0;
                                            }
                                            generationUnit = newGenerationUnit;
                                        }
                                        if (!model.params.psr_type) {
                                            series.color = colorMap[psrType];
                                        } else {
                                            series.color = colors[colorCount];
                                        }
                                        if (model.params.psr_type === "") {
                                            if (!dict[psrType]) {
                                                dict[psrType] = [
                                                    {
                                                        renewable: true,
                                                        name: psrTypes[
                                                            psrType
                                                        ].toUpperCase(),
                                                        color: colorMap[
                                                            psrType
                                                        ],
                                                        events: {
                                                            legendItemClick() {
                                                                const chart =
                                                                    this.chart;
                                                                const series =
                                                                    chart.series;
                                                                series.forEach(
                                                                    (
                                                                        series
                                                                    ) => {
                                                                        if (
                                                                            meta
                                                                                .series[
                                                                                series
                                                                                    .name
                                                                            ] &&
                                                                            meta
                                                                                .series[
                                                                                series
                                                                                    .name
                                                                            ]
                                                                                .psr_type ===
                                                                                psrType.toUpperCase()
                                                                        ) {
                                                                            series.setVisible(
                                                                                !this
                                                                                    .visible,
                                                                                false
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                                chart.redraw();
                                                                setTimeout(
                                                                    () => {
                                                                        let hiddenCols =
                                                                            [];
                                                                        let visibleCols =
                                                                            [];
                                                                        let table =
                                                                            model
                                                                                .tableObjects
                                                                                .table;
                                                                        $.each(
                                                                            chart.series,
                                                                            function (
                                                                                i,
                                                                                seriex
                                                                            ) {
                                                                                let name =
                                                                                    seriex.name;
                                                                                if (
                                                                                    !name.startsWith(
                                                                                        "Nav"
                                                                                    )
                                                                                ) {
                                                                                    let isVisible =
                                                                                        seriex.visible;
                                                                                    if (
                                                                                        isVisible
                                                                                    ) {
                                                                                        visibleCols.push(
                                                                                            `${name}:name`
                                                                                        );
                                                                                        model.visibleColumns.add(
                                                                                            name
                                                                                        );
                                                                                    } else {
                                                                                        hiddenCols.push(
                                                                                            `${name}:name`
                                                                                        );
                                                                                        model.visibleColumns.delete(
                                                                                            name
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                        );
                                                                        table
                                                                            .columns(
                                                                                visibleCols
                                                                            )
                                                                            .visible(
                                                                                true,
                                                                                false
                                                                            );
                                                                        table
                                                                            .columns(
                                                                                hiddenCols
                                                                            )
                                                                            .visible(
                                                                                false,
                                                                                false
                                                                            );
                                                                        debounce(
                                                                            updateAllTables(
                                                                                model
                                                                            )
                                                                        );
                                                                    },
                                                                    500
                                                                );
                                                            },
                                                        },
                                                    },
                                                ];
                                            }
                                            dict[psrType].push(series);
                                        } else {
                                            if (!dict[generationUnit]) {
                                                dict[generationUnit] = [
                                                    {
                                                        renewable: true,
                                                        name: generationUnit,
                                                        // name: psrTypes[
                                                        //     psrType
                                                        // ].toUpperCase(),
                                                        color: colorMap[
                                                            generationUnit
                                                        ],
                                                        events: {
                                                            legendItemClick() {
                                                                const chart =
                                                                    this.chart;
                                                                const series =
                                                                    chart.series;
                                                                series.forEach(
                                                                    (
                                                                        series
                                                                    ) => {
                                                                        if (
                                                                            meta
                                                                                .series[
                                                                                series
                                                                                    .name
                                                                            ] &&
                                                                            meta
                                                                                .series[
                                                                                series
                                                                                    .name
                                                                            ]
                                                                                .generation_unit ===
                                                                                newGenerationUnit
                                                                        ) {
                                                                            series.setVisible(
                                                                                !this
                                                                                    .visible,
                                                                                false
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                                chart.redraw();
                                                                setTimeout(
                                                                    () => {
                                                                        let hiddenCols =
                                                                            [];
                                                                        let visibleCols =
                                                                            [];
                                                                        let table =
                                                                            model
                                                                                .tableObjects
                                                                                .table;
                                                                        $.each(
                                                                            chart.series,
                                                                            function (
                                                                                i,
                                                                                seriex
                                                                            ) {
                                                                                let name =
                                                                                    seriex.name;
                                                                                if (
                                                                                    !name.startsWith(
                                                                                        "Nav"
                                                                                    )
                                                                                ) {
                                                                                    let isVisible =
                                                                                        seriex.visible;
                                                                                    if (
                                                                                        isVisible
                                                                                    ) {
                                                                                        visibleCols.push(
                                                                                            `${name}:name`
                                                                                        );
                                                                                        model.visibleColumns.add(
                                                                                            name
                                                                                        );
                                                                                    } else {
                                                                                        hiddenCols.push(
                                                                                            `${name}:name`
                                                                                        );
                                                                                        model.visibleColumns.delete(
                                                                                            name
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                        );
                                                                        table
                                                                            .columns(
                                                                                visibleCols
                                                                            )
                                                                            .visible(
                                                                                true,
                                                                                false
                                                                            );
                                                                        table
                                                                            .columns(
                                                                                hiddenCols
                                                                            )
                                                                            .visible(
                                                                                false,
                                                                                false
                                                                            );
                                                                        debounce(
                                                                            updateAllTables(
                                                                                model
                                                                            )
                                                                        );
                                                                    },
                                                                    500
                                                                );
                                                            },
                                                        },
                                                    },
                                                ];
                                            }
                                            dict[generationUnit].push(series);
                                        }
                                    } else {
                                        navs.push(series);
                                    }
                                }
                                for (let s in dict) {
                                    if (renewablePsrTypes.includes(s)) {
                                        renewables = [
                                            ...renewables,
                                            ...dict[s],
                                        ];
                                    } else {
                                        conventionals = [
                                            ...conventionals,
                                            ...dict[s],
                                        ];
                                    }
                                }
                                let newParsedSeries = [
                                    ...renewables,
                                    ...conventionals,
                                ];
                                parsedData.series = newParsedSeries;
                            }
                            for (let series of parsedData.series) {
                                if (
                                    series.name === "load_val" ||
                                    series.name === "renewables_percent"
                                ) {
                                    series.type = "line";
                                    series.showInNavigator = false;
                                } else {
                                    series.showInNavigator = true;
                                }
                                if (
                                    [
                                        "conventionals_value",
                                        "renewables_value",
                                        "total_value",
                                        "em_total_value",
                                    ].includes(series.name)
                                ) {
                                    series.type = "line";
                                    series.color = "transparent";
                                    series.borderColor = "transparent";
                                    series.showInLegend = false;
                                    series.showInNavigator = false;
                                }
                                if (series.name === "renewables_percent") {
                                    series.dashStyle = "shortdot";
                                    series.yAxis = 1;
                                }
                                if (series.name === "load_val") {
                                    series.dashStyle = "dash";
                                    series.zIndex = 1000;
                                }
                                if (series.showInLegend) {
                                    if (!series.events) {
                                        series.events = {};
                                    }
                                }
                            }
                        }
                        if (prefix === "psr-generation") {
                            for (let series of parsedData.series) {
                                if (series.name.startsWith("ic_")) {
                                    series.type = "area";
                                    series.showInNavigator = false;
                                    series.color = "#e5f4e5";
                                    series.yAxis = 1;
                                } else {
                                    series.zIndex = 1000;
                                }
                            }
                        }
                        if (prefix === "installed-capacity") {
                            let genSeries = [];
                            for (let series of parsedData.series) {
                                if (series.name.startsWith("ic_b")) {
                                    let key = series.name
                                        .replace("ic_", "")
                                        .replace("_value", "")
                                        .replace("_val", "")
                                        .replace("_percent", "");
                                    series.color = colorMap[key];
                                    genSeries.push(series);
                                }
                                if (series.name === "renewables_percent") {
                                    series.color = colorMap["renewables"];
                                }
                                if (
                                    series.name === "total_value" &&
                                    isPercentChart
                                ) {
                                    series.visible = false;
                                }
                                if (
                                    series.name === "renewables_value" &&
                                    isPercentChart
                                ) {
                                    series.visible = false;
                                }
                            }
                            genSeries.reverse();
                            parsedData.series = genSeries.concat(
                                parsedData.series.slice(-4)
                            );
                        }
                        if (prefix === "total-generation") {
                            let genSeries = [];
                            for (let series of parsedData.series) {
                                if (series.name.startsWith("b")) {
                                    let key = series.name
                                        .replace("ic_", "")
                                        .replace("_value", "")
                                        .replace("_val", "")
                                        .replace("_percent", "");
                                    series.color = colorMap[key];
                                    genSeries.push(series);
                                }
                                if (series.name === "renewables_percent") {
                                    series.color = colorMap["renewables"];
                                }
                            }
                            genSeries.reverse();

                            parsedData.series = [parsedData.series[0]].concat(
                                genSeries.concat(parsedData.series.slice(-4))
                            );
                            for (let [
                                series,
                                index,
                            ] of parsedData.series.entries()) {
                                series.legendIndex = index;
                            }
                        }
                        if (prefix === "total-emissions") {
                            let genSeries = [];
                            for (let series of parsedData.series) {
                                if (series.name.startsWith("em_")) {
                                    let key = series.name
                                        .replace("ic_", "")
                                        .replace("em_", "")
                                        .replace("_value", "")
                                        .replace("_val", "")
                                        .replace("_percent", "");
                                    series.color = colorMap[key];
                                    if (series.name === "em_total_value") {
                                        series.color = "transparent";
                                    }
                                    genSeries.push(series);
                                }
                                if (series.name === "renewables_percent") {
                                    series.color = colorMap["renewables"];
                                }
                            }
                            genSeries.reverse();

                            parsedData.series = genSeries;
                            for (let [
                                series,
                                index,
                            ] of parsedData.series.entries()) {
                                series.legendIndex = index;
                            }
                        }
                        if (prefix === "flows") {
                            for (let series of parsedData.series) {
                                if (series.name !== "flow_net_nrw_val") {
                                    series.visible = false;
                                }
                            }
                        }
                        return parsedData;
                    },
                },
                legend: {
                    itemStyle: {
                        font: "1.2em",
                    },
                    enabled: true,
                    labelFormatter: function () {
                        if (prefix === "psr-generation") {
                            if (this.name.startsWith("ic_")) {
                                return "Installierte Leistung";
                            }
                        }
                        let name = `${
                            labels[this.name] ? labels[this.name] : this.name
                        }`;
                        return name;
                    },
                },
            },
            function (chart) {
                $.each(chart.series, function (i, serie) {
                    let legendItem = serie.legendItem;
                    if (legendItem) {
                        $(legendItem.group.element).bind(
                            "dblclick",
                            function (e) {
                                $.each(chart.series, function (i, seriex) {
                                    if (!seriex.name.startsWith("Nav")) {
                                        if (
                                            serie.name ===
                                            "Erneuerbare Energien"
                                        ) {
                                            if (
                                                seriex.name === serie.name ||
                                                reverseRenewables.includes(
                                                    seriex.name
                                                )
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else if (
                                                meta.series[seriex.name] &&
                                                renewablePsrTypes.includes(
                                                    meta.series[seriex.name]
                                                        .psr_type
                                                )
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else {
                                                seriex.setVisible(false, false);
                                            }
                                        } else if (
                                            serie.name ===
                                            "Konventionelle Energien"
                                        ) {
                                            if (
                                                seriex.name === serie.name ||
                                                reverseConventionals.includes(
                                                    seriex.name
                                                )
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else if (
                                                meta.series[seriex.name] &&
                                                !renewablePsrTypes.includes(
                                                    meta.series[seriex.name]
                                                        .psr_type
                                                )
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else {
                                                seriex.setVisible(false, false);
                                            }
                                        } else if (
                                            reverseConventionals.includes(
                                                serie.name
                                            ) ||
                                            reverseRenewables.includes(
                                                serie.name
                                            ) ||
                                            (model.prefix ===
                                                "block-generation" &&
                                                model.params.psr_type !== "")
                                        ) {
                                            if (seriex.name === serie.name) {
                                                seriex.setVisible(true, false);
                                            } else if (
                                                meta.series[seriex.name] &&
                                                meta.series[
                                                    seriex.name
                                                ].psr_type.toLowerCase() ===
                                                    reversePsrTypes[serie.name]
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else if (
                                                meta.series[seriex.name] &&
                                                meta.series[seriex.name]
                                                    .generation_unit ===
                                                    serie.name
                                            ) {
                                                seriex.setVisible(true, false);
                                            } else {
                                                seriex.setVisible(false, false);
                                            }
                                        } else if (seriex.name !== serie.name) {
                                            if (
                                                serie.name ===
                                                "renewables_percent"
                                            ) {
                                                if (
                                                    [
                                                        "conventionals_value",
                                                        "renewables_value",
                                                        "total_value",
                                                        "em_total_value",
                                                    ].includes(seriex.name)
                                                ) {
                                                    seriex.setVisible(
                                                        true,
                                                        false
                                                    );
                                                } else {
                                                    seriex.setVisible(
                                                        false,
                                                        false
                                                    );
                                                }
                                            } else {
                                                seriex.setVisible(false, false);
                                            }
                                        } else {
                                            seriex.setVisible(true, false);
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                                chart.redraw();
                                setTimeout(() => {
                                    let hiddenCols = [];
                                    let visibleCols = [];
                                    let table = model.tableObjects.table;
                                    $.each(chart.series, function (i, seriex) {
                                        let name = seriex.name;
                                        if (!name.startsWith("Nav")) {
                                            let isVisible = seriex.visible;
                                            if (isVisible) {
                                                visibleCols.push(
                                                    `${name}:name`
                                                );
                                                model.visibleColumns.add(name);
                                            } else {
                                                hiddenCols.push(`${name}:name`);
                                                model.visibleColumns.delete(
                                                    name
                                                );
                                            }
                                        } else {
                                            return false;
                                        }
                                    });
                                    table
                                        .columns(visibleCols)
                                        .visible(true, false);
                                    table
                                        .columns(hiddenCols)
                                        .visible(false, false);
                                    debounce(updateAllTables(model));
                                }, 500);
                            }
                        );
                    }
                });
            }
        );
        return chart;
    }

    async function getParams(model) {
        let prefix = model.prefix;
        let params = {};
        for (let parameterName of parameterNames) {
            let formElement = $(`#${prefix}-form-${parameterName}`);
            if (!formElement) {
                continue;
            }
            let value = formElement.val();
            if (parameterName === "year") {
                if (value === "0") {
                    params.start = `2015-01-01T00:00:00+01:00`;
                    params.end = `${moment().year()}-12-31T23:45:00+01:00`;
                } else {
                    params.start = `${
                        parseInt(value) - 1
                    }-11-30T23:45:00+01:00`;
                    params.end = `${parseInt(value) + 1}-01-31T23:45:00+01:00`;
                }
                params[parameterName] = value;
            } else {
                params[parameterName] = value;
            }
        }
        return params;
    }

    async function finishUp(model, viewType) {
        if (viewType === "plot") {
            model.tableObjects = await makeTable(model, oldModel);
            $(`#${prefix}-table`)
                .css("width", "100%")
                .dataTable()
                .fnAdjustColumnSizing();
        } else if (viewType === "table") {
            model.chart = await makeChart(model, oldModel);
            makeLegendItemsBold(model);
        }
        // Refilter the table and chart
        $("#min-date-input").on("change", function (event) {
            let min;
            let el = event.target;
            if (event.target.value.length !== 10) {
                el.style.color = "red";
                alert(
                    `Ungültiges Datumsformat. Beispiel gültiges Datum: ${model.datepickerMin}`
                );
                return;
            }
            if (event.target.value < model.datepickerMin) {
                alert(
                    `Ungültiges Datum. Um Daten früher als ${model.datepickerMin} anzuzeigen, wählen Sie ein anderes Jahr aus. Daten von ${model.datepickerMin} werden nun angezeigt`
                );
                min = Date.parse(model.datepickerMin);
                event.target.value = model.datepickerMin;
            } else {
                min = Date.parse(event.target.value);
            }
            el.style.color = "black";
            model.minDateSearch = event.target.value;
            debounce(function () {
                updateExtremeValues(model);
                updateExtremeValuesTable(model);
            });
            model.tableObjects.table.draw();
            let max = Date.parse($("#max-date-input").val());
            if (model.chart) {
                model.chart.xAxis[0].setExtremes(min, max);
            }
        });

        $("#max-date-input").on("change", function (event) {
            let max;
            let el = event.target;
            if (event.target.value.length !== 10) {
                el.style.color = "red";
                alert(
                    `Ungültiges Datumsformat. Beispiel gültiges Datum: ${model.datepickerMax}`
                );
                return;
            }
            if (event.target.value > model.datepickerMax) {
                alert(
                    `Ungültiges Datum. Um Daten später als ${model.datepickerMax} anzuzeigen, wählen Sie ein anderes Jahr aus. Daten von ${model.datepickerMax} werden nun angezeigt`
                );
                max = Date.parse(model.datepickerMax);
                event.target.value = model.datepickerMax;
            } else {
                max = Date.parse(event.target.value);
            }
            el.style.color = "black";
            model.maxDateSearch = event.target.value;
            model.tableObjects.table.draw();
            debounce(function () {
                updateExtremeValues(model);
                updateExtremeValuesTable(model);
            });
            let min = Date.parse($("#min-date-input").val());
            if (model.chart) {
                model.chart.xAxis[0].setExtremes(min, max);
            }
        });

        // Set up custom image download buttons
        ["jpeg", "png"].forEach((fileFormat) => {
            $(`#${prefix}-download-${fileFormat}`).on("click", function (e) {
                e.preventDefault();
                let metadata = model.data.metadata;
                if (
                    metadata.has_data_gaps ||
                    metadata.has_plausibility_errors
                ) {
                    confirm("Hinweis: Datenqualitätsthemen vorhanden.");
                }
                let sourceHeight = prefix === "block-generation" ? 1000 : 500;
                model.chart.exportChartLocal(
                    {
                        type: `image/${fileFormat}`,
                        filename: makeFilename(),
                        sourceHeight: sourceHeight,
                    },
                    {
                        navigator: {
                            enabled: false,
                        },
                        scrollbar: {
                            enabled: false,
                        },
                    }
                );
            });
        });

        // Set up custom tabular download buttons
        ["csv", "xlsx"].forEach((fileFormat, index) => {
            $(`#${prefix}-download-${fileFormat}`).on("click", function (e) {
                e.preventDefault();
                let metadata = model.data.metadata;
                if (
                    metadata.has_data_gaps ||
                    metadata.has_plausibility_errors
                ) {
                    confirm("Hinweis: Datenqualitätsthemen vorhanden.");
                }
                model.tableObjects.table.buttons(index).trigger();
            });
            $(`#${prefix}-extreme-values-download-${fileFormat}`).on(
                "click",
                function (e) {
                    e.preventDefault();
                    let metadata = model.data.metadata;
                    if (
                        metadata.has_data_gaps ||
                        metadata.has_plausibility_errors
                    ) {
                        confirm("Hinweis: Datenqualitätsthemen vorhanden.");
                    }
                    model.extremeValuesTable.buttons(index).trigger();
                }
            );
        });

        model.extremeValues = await getExtremeValues(model);
        model = await updateExtremeValues(model);
        model.extremeValuesTable = await makeExtremeValuesTable(
            model,
            oldModel
        );
        extremeValuesTableContainer.removeClass("hidden");

        document.addEventListener(
            "dashboardMinMax",
            updateAfterDashboardMinMax
        );
        return model;
    }

    $("#min-date-input").off("change");
    $("#max-date-input").off("change");
    document.removeEventListener("dashboardMinMax", updateAfterDashboardMinMax);

    // Remove chart download events to prevent error in case we're adding a new chart.
    ["jpeg", "png"].forEach((fileFormat) => {
        $(`#${prefix}-download-${fileFormat}`).off("click");
    });

    model.prefix = prefix;
    model.endpoint = await getEndpoint(model);
    model.params = await getParams(model);
    model.data = await getData(model);

    if (viewType === "plot") {
        model.chart = await makeChart(model, oldModel);
        makeLegendItemsBold(model);
        model = await finishUp(model, viewType);
        plotContainer.removeClass("hidden");
        dataviewSpinnerContainer.addClass("hidden");
    }

    if (viewType === "table") {
        model.tableObjects = await makeTable(model, oldModel);
        model = await finishUp(model, viewType);
        tableContainer.removeClass("hidden");
        dataviewSpinnerContainer.addClass("hidden");
    }

    return model;
}

function makeDataViews(initParams) {
    document.addEventListener("DOMContentLoaded", function () {
        if (document.getElementById("start-deeplink")) {
            updateEmbedCode(null);
        }
        $("div[id$='-dataview']").each(async function (i, el) {
            let prefix = el.id.replace("-dataview", "");
            // Decide which view to show
            let tableHasBeenViewed = false;
            if ($(`#${prefix}-form-view`).val() === "plot") {
                document
                    .getElementById(`${prefix}-plot`)
                    .classList.remove("hidden");
            } else {
                document
                    .getElementById(`${prefix}-table-container`)
                    .classList.remove("hidden");
                tableHasBeenViewed = true;
            }

            model = await makeDataView(prefix);

            // Set up view toggles
            // Format toggle
            $(`#${prefix}-form-view`).on("change", function () {
                if (this.value == "plot") {
                    document
                        .getElementById(`${prefix}-table-container`)
                        .classList.add("hidden"); // Use hidden instead of d-none as we're working with Bootstrap 3
                    document
                        .getElementById(`${prefix}-plot`)
                        .classList.remove("hidden"); // Use hidden instead of d-none as we're working with Bootstrap 3
                } else if (this.value === "table") {
                    document
                        .getElementById(`${prefix}-plot`)
                        .classList.add("hidden"); // Use hidden instead of d-none as we're working with Bootstrap 3
                    document
                        .getElementById(`${prefix}-table-container`)
                        .classList.remove("hidden"); // Use hidden instead of d-none as we're working with Bootstrap 3
                    $(`#${prefix}-table`)
                        .css("width", "100%")
                        .dataTable()
                        .fnAdjustColumnSizing();
                } else {
                    console.warn(`View "${this.value}" not implemented..."`);
                }
                updateEmbedCode(model);
            });
            // Parameter toggles
            for (let parameterName of parameterNames) {
                let selectElement = $(`#${prefix}-form-${parameterName}`);
                if (!selectElement) {
                    continue;
                }
                let oldModel;
                selectElement.on("change", async function () {
                    if (parameterName === "year") {
                        if (this.value === "0") {
                            let resolutionElement = $(
                                `#${prefix}-form-resolution`
                            );
                            if (
                                resolutionElement.val() !== "half_yearly" ||
                                resolutionElement.val() !== "yearly" ||
                                resolutionElement.val() !== "quarterly"
                            ) {
                                resolutionElement.val("yearly");
                            }
                        } else {
                            let resolutionElement = $(
                                `#${prefix}-form-resolution`
                            );
                            if (
                                resolutionElement.val() === "half_yearly" ||
                                resolutionElement.val() === "yearly" ||
                                resolutionElement.val() === "quarterly"
                            ) {
                                resolutionElement.val("hourly");
                            }
                        }
                        oldModel = model;
                    } else {
                        oldModel = model;
                    }
                    if (parameterName === "resolution") {
                        if (
                            this.value === "half_yearly" ||
                            this.value === "yearly" ||
                            this.value === "quarterly"
                        ) {
                            let yearElement = $(`#${prefix}-form-year`);
                            if (yearElement.val() !== "0") {
                                yearElement.val("0");
                            }
                            oldModel = model;
                        } else {
                            let yearElement = $(`#${prefix}-form-year`);
                            if (yearElement.val() === "0") {
                                yearElement.val(`${moment().year()}`);
                            }
                            if (
                                oldModel &&
                                ["quarterly", "half_yearly", "yearly"].includes(
                                    oldModel.params.resolution
                                )
                            ) {
                                oldModel = model;
                            }
                        }
                    }
                    model = await makeDataView(prefix, oldModel);
                    updateEmbedCode(model);
                    model.tableObjects.table.draw();
                });
            }
            $.fn.dataTable.ext.search.push(function (
                settings,
                data,
                dataIndex
            ) {
                // Don't search if applied to the extreme values table
                if (settings.nTable.id.endsWith("extreme-values-table")) {
                    return true;
                }
                var min = model.minDateSearch;
                var max = model.maxDateSearch;
                var date = data[0].slice(0, 10);
                if (
                    (min === null && max === null) ||
                    (min === null && date <= max) ||
                    (min <= date && max === null) ||
                    (min <= date && date <= max)
                ) {
                    return true;
                }
                return false;
            });
            // We need to redraw the table after setting up the search function
            // so that the tabular exports will be filtered on first load.
            model.tableObjects.table.draw();
            updateEmbedCode(model);
        });
    });
}

function makeLegendItemsBold(model) {
    function embolden(content) {
        $(`text:contains(${content})`).css("font-weight", "bold");
    }
    if (model.prefix === "block-generation") {
        if (model.params.psr_type === "") {
            for (let psrType of Object.values(psrTypes)) {
                embolden(psrType.toUpperCase());
            }
        } else {
            for (let generationUnit of Object.values(generationUnits)) {
                embolden(generationUnit);
            }
        }
    }
}

function makeEmbedURL(model) {
    let params = JSON.parse(JSON.stringify(model.params));
    params.start = "";
    params.end = "";
    params = Object.fromEntries(
        Object.entries(params).filter(([_, v]) => v != "")
    );
    let view = $(`#${model.prefix}-form-view`).val();
    if (view) {
        params.view = view;
    }
    let url = window.location.origin + window.location.pathname;
    if (Object.keys(params).length) {
        let queryParams = new URLSearchParams(params);
        url = url + "?" + queryParams;
    }
    return url;
}

function makeEmbedCode(model) {
    let url = makeEmbedURL(model);
    let title = titles[model.prefix];
    let embedCode = {
        url: url,
        title: title ? title : "Strommarktmonitoring NRW",
    };
    return embedCode;
}

function updateEmbedCode(model) {
    if (!model) {
        model = { params: {}, prefix: "start" };
    }
    let embedCode = makeEmbedCode(model);
    let iFrame = `<iframe title="${embedCode.title}" src="${embedCode.url}" style="width: 100%; height: 687px; overflow: hidden;" scrolling="no" seamless=""></iframe>`;
    let deeplink = document.getElementById(`${model.prefix}-deeplink`);
    deeplink.textContent = embedCode.url;
    deeplink.href = embedCode.url;
    document.getElementById(`${model.prefix}-embed-code`).textContent = iFrame;
}

function init() {
    let initParams = JSON.parse(
        document.getElementById("init_params").textContent
    );
    initSentry();
    initSidebarCollapse();
    animateTickerNumbers();
    makeDataViews(initParams);
}

init();
